
//kirklands ELITE BLUE 
$color__brand-blue: #2354E8;

$color__brand-teal: #1AC6BA;
$color__brand-coral: #FF715B;
$color__brand-gray: #E3E3E3;

$color__black: #1C1C1C;
$color__white: #fff;
$color__grey: #7C7C7C;

//kirklands alternate blue (for dark backgrounds)
$color__blue-light: #7090F0;

$color__gray-text: $color__grey;
$color__gray-shady-lady: #979797;
$color__gray-charcoal: #3F3F3F;
$color__gray-charcoal-alt: #505050;

//for hover states that used to be coral 
// $color__gray-hover: $color__gray-charcoal;
$color__gray-hover--for-gray-background: $color__gray-charcoal;
$color__gray-hover--for-white-background: $color__gray-charcoal-alt;

// used with links on white background
// DENIED
// kirkland wants one blue only for light background
// $color__blue-royal-blue: #4460DB;

$color__white--opaque: rgba(255, 255, 255, 0.95);
$color__box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);

$color__gradient--end: rgba(35,84,232, 100);
$color__gradient--start: rgba(68,96,219, 0);

$color__gradient-gray--end: rgba(227,227,227, 100);
$color__gradient-gray--start: rgba(227,227,227, 0);

$color__border-gray: $color__brand-gray;