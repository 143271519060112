﻿img, figure {
    // max-height: 200px;
    // height: auto;
    max-width: 100%;
    height: auto !important;
    //use same responsive image styling as RTE here
}

section, .section {
    margin-top: 2em;
}


.titles__subheading-title br {
    display: none;
} 




/***********************
* Bio
************************/
.lawyers-listing__meta:not(:last-child):after {
    content: "/";
    margin: 0 .5em;
}

.profile-heading {
    &__position {
        &:after {
          content: '/';
          color: $color__gray-text;
          margin-left: 0.65em;
          margin-right: 0.25em;
        }
    }

    &__snapshot {
        width: 200px;
        height: auto !important;
    }

    &__specialty,
    &__position {
    
        &--no-separator::after {
            display: none;
        }
    }

    .expandable-services__body {
        height: auto !important;
    }
}

.rte {
    a{
        @include links__blue-on-light;
    }
}