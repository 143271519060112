.print-view-hide {
    display: none;
}


div.print-view-show, 
h1.print-view-show, 
h2.print-view-show, 
h3.print-view-show {
    display:block !important;
}

span.print-view-show,
{
    display: inline !important;
}



//specific elements can have print-view-hide class
//put elements here that are very common to get them all in one go

.site-footer, 
.site-header, 
.site-overlay, 
.language-dropdown, 
.read-more__button,
.related-insights__more,
canvas, 
.accordion__icon
{
    display: none;
}




