/* Variables */
$icomoon-font-path: "/assets/public/icons/" !default;

$ico-icon-name: "kirkland";

$icomoon-hash: z3e1ry;


@mixin icon-face-family($font-family, $file-path, $font-weight:normal, $font-style: normal) {
  @font-face {
  	font-family: $font-family;
  	src:  url('#{$file-path}#{$font-family}.ttf') format('truetype'),
					url('#{$file-path}#{$font-family}.woff') format('woff'),
					url('#{$file-path}#{$font-family}.svg?z3e1ry##{$file-path}') format('svg');
		font-weight: $font-weight;
		font-style: $font-style;
    font-display: swap;
  }
}

//sass mappings
$icons: (
  google-plus: "\e900",
  facebook: "\e901",
  twitter: "\e902",
  x: "\e90e",
  linkedin: "\e903",
  youtube:"\ea9d",
  instagram:"\ea92",
  chevron-right: "\e90f",
  chevron-left: "\e910",
  chevron-thin-up: "\e905",
  chevron-thin-right: "\e906",
  chevron-thin-left: "\e907",
  chevron-thin-down: "\e908",
  search: "\e909",
  close: "\e904",
  globe: "\e90d",
  marker: "\f0ca",
  external-link: "\e90b",
  download: "\e90a",
  envelope: "\e90c",
  play-arrow: "\e037",
);


//Mixin to load icon font
@include icon-face-family($ico-icon-name, $icomoon-font-path);

//attribute selector of class name for icons
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $ico-icon-name !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


//Generate Class for each icon based on sass mapping object
@each $name, $icon in $icons {
  .icon-#{$name}:before {
    content: $icon;
  }
}
