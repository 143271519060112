.normalized-rte-list {

    // padding-bottom: .5em;
    margin-bottom: .5em;

    &:after {
        content: '';
		display: block;
		width: px-to-em(30px);
		height: 1px;
		background-color: $color__gray-text;
		margin-top: px-to-em(24px);
		margin-bottom: px-to-em(24px);
    }

    &:last-child:after {
        display: none;
    }


    // &__title {

    // }

    &__item{
        &:not(&--no-comma){
            &:not(:last-child) {
                &:after {
                    content: ', '
                }
            }
        }
        

        //stop random paragraphs in RTE from line breaking except for education details
        p {
            margin: 0;
        }
        &:not(&--edu-details){
            p {
                display: inline;
            }
        }
        

        // br {
        //     display: none;
        // }
    }

}