//------------------------------------------
//				Variables
//------------------------------------------
$font-size-base: 16px;
$font__line-height: 1.5;

$font-size__h1: 28px !default;
$font-size__h2: 20px !default;
$font-size__h3: 18px !default;
$font-size__h4: 16px !default;
$font-size__h5: 16px !default;
$font-size__h6: 14px !default;

$font-size__h1--tablet: 48px !default;
$font-size__h2--tablet: 24px !default;
$font-size__h3--tablet: 20px !default;
$font-size__h4--tablet: 18px !default;
$font-size__h3--tablet-custom: 12px !default;

// Isolated Titles
$font-size__hero: 32px ;
$font-size__hero--tablet: 85px;

$font-size__subheading: 20px;
$font-size__subheading--tablet: 24px;

$font-size__prominent-card: 20px;
$font-size__prominent-card--tablet: 28px;

$font-size__page-title: 32px ;
$font-size__page-title--tablet: 64px ;

$font-size__banner: 24px !default;
$font-size__banner--tablet: 38px !default;

$font-size__banner--custom-tablet: 36px !default;
$font-size__banner--custom: 22px !default;
$font-size__h3-custom: 20px !default;

$font-size__banner--search-result: 22px !default;

$font-size__small: 14px;
$font-size__smallest: 12px;

$font-size__copy--tablet: 20px;

$font-size__copy-blockquote: 18px;
$font-size__copy-blockquote--tablet: 28px;

$font-size__office-alert: 18px;
$font-size__office-alert--tablet: 16px;

$font-size__testimonial-result-title: 24px;
$font-size__testimonial-result-title--tablet: 32px;


//------------------------------------------
//				Helpful Functions for font conversion to EM
//------------------------------------------
@function px-to-em($pixels, $base: null) {
  @if $base == null {
    $base: $font-size-base;
  }
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

	@return ($pixels / $base) * 1em;
}
//for ie
@function rem($pixels, $base: null) {
  @if $base == null {
    $base: $font-size-base;
  }
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

	@return ($pixels / $base) * 1rem;
}

//------------------------------------------
//				Font Families and Weights
//				
//				Rubik:
//          light, light italic
//					regular,
//					bold
//
//				Playfair :
//
//					regular, Bold
//------------------------------------------

$font-family__rubik: 'Rubik', sans-serif;
$font-family__playfair: 'Playfair Display', serif;

$font-family__titles: $font-family__playfair;
$font-family__copy: $font-family__rubik;

$font-weights: (
  light: 300,
  regular: 400,
  normal: 400,
  medium: 500,
	bold: 700
);

@mixin font($family: null, $size: null, $weight: null, $size-base: null ) {
  font-family: $family;

	@if $size != null {
		font-size: px-to-em($size, $size-base);
  }

	@if map-has-key($font-weights, $weight) {
		font-weight: map-get($font-weights, $weight);
	}
}
