﻿html {
	font-size: 100%;
	box-sizing: border-box;
	height: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
    margin: .5in;
    
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
  font: map-get($font-weights, regular) 100% $font-family__copy;
	line-height: $font__line-height;
	color: $color__gray-charcoal;
}

main {
	display: block;
	background-color: $color__white;
}

a {
	color: inherit;
	text-decoration: none;
	&:focus {
		outline: none;
	}
}

ul {
    padding-left: 16px;
    margin-top: 0;
    margin-bottom: 0;
}
li {
	margin-bottom: 3px;
}

pre {
	margin: 0;
}

p {
  margin:0 0 px-to-em($spacing__normalize);

  &:last-child {
  	margin-bottom: 0;
  }
}

h1,h2,h3,h4,h5,h6 {
//   margin: 0;
  color: $color__black;
  
// @include font($family: 'Georgia', $weight: 'regular');
// font-weight: bold;
@include font($family: $font-family__titles, $weight: 'regular');
    
}
h1 {
    font-weight: normal;
}

.rte {
    ul {
        margin-bottom: 24px;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    ol {
        list-style-type: decimal;

        ol {
            margin-top: px-to-em($spacing__normalize/2);
            list-style-type: lower-alpha;

            ol {
                list-style-type: lower-roman;
            }
        }
    }
}