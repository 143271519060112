@charset "UTF-8";
/* Variables */
@font-face {
  font-family: "kirkland";
  src: url("/assets/public/icons/kirkland.ttf") format("truetype"), url("/assets/public/icons/kirkland.woff") format("woff"), url("/assets/public/icons/kirkland.svg?z3e1ry#/assets/public/icons/") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "kirkland" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-google-plus:before {
  content: "\e900";
}

.icon-facebook:before {
  content: "\e901";
}

.icon-twitter:before {
  content: "\e902";
}

.icon-x:before {
  content: "\e90e";
}

.icon-linkedin:before {
  content: "\e903";
}

.icon-youtube:before {
  content: "\ea9d";
}

.icon-instagram:before {
  content: "\ea92";
}

.icon-chevron-right:before {
  content: "\e90f";
}

.icon-chevron-left:before {
  content: "\e910";
}

.icon-chevron-thin-up:before {
  content: "\e905";
}

.icon-chevron-thin-right:before {
  content: "\e906";
}

.icon-chevron-thin-left:before {
  content: "\e907";
}

.icon-chevron-thin-down:before {
  content: "\e908";
}

.icon-search:before {
  content: "\e909";
}

.icon-close:before {
  content: "\e904";
}

.icon-globe:before {
  content: "\e90d";
}

.icon-marker:before {
  content: "\f0ca";
}

.icon-external-link:before {
  content: "\e90b";
}

.icon-download:before {
  content: "\e90a";
}

.icon-envelope:before {
  content: "\e90c";
}

.icon-play-arrow:before {
  content: "\e037";
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  background: transparent;
}
a:focus {
  outline: thin dotted;
}
a:hover, a:active {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: "“" "”" "‘" "’";
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button, input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

html {
  font-size: 100%;
  box-sizing: border-box;
  height: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0.5in;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 400 100% "Rubik", sans-serif;
  line-height: 1.5;
  color: #3F3F3F;
}

main {
  display: block;
  background-color: #fff;
}

a {
  color: inherit;
  text-decoration: none;
}
a:focus {
  outline: none;
}

ul {
  padding-left: 16px;
  margin-top: 0;
  margin-bottom: 0;
}

li {
  margin-bottom: 3px;
}

pre {
  margin: 0;
}

p {
  margin: 0 0 1.5em;
}
p:last-child {
  margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: #1C1C1C;
  font-family: "Playfair Display", serif;
  font-weight: 400;
}

h1 {
  font-weight: normal;
}

.rte ul {
  margin-bottom: 24px;
}
.rte:first-child {
  margin-top: 0;
}
.rte:last-child {
  margin-bottom: 0;
}
.rte ol {
  list-style-type: decimal;
}
.rte ol ol {
  margin-top: 0.75em;
  list-style-type: lower-alpha;
}
.rte ol ol ol {
  list-style-type: lower-roman;
}

/* Variables */
@font-face {
  font-family: "kirkland";
  src: url("/assets/public/icons/kirkland.ttf") format("truetype"), url("/assets/public/icons/kirkland.woff") format("woff"), url("/assets/public/icons/kirkland.svg?z3e1ry#/assets/public/icons/") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "kirkland" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-google-plus:before {
  content: "\e900";
}

.icon-facebook:before {
  content: "\e901";
}

.icon-twitter:before {
  content: "\e902";
}

.icon-x:before {
  content: "\e90e";
}

.icon-linkedin:before {
  content: "\e903";
}

.icon-youtube:before {
  content: "\ea9d";
}

.icon-instagram:before {
  content: "\ea92";
}

.icon-chevron-right:before {
  content: "\e90f";
}

.icon-chevron-left:before {
  content: "\e910";
}

.icon-chevron-thin-up:before {
  content: "\e905";
}

.icon-chevron-thin-right:before {
  content: "\e906";
}

.icon-chevron-thin-left:before {
  content: "\e907";
}

.icon-chevron-thin-down:before {
  content: "\e908";
}

.icon-search:before {
  content: "\e909";
}

.icon-close:before {
  content: "\e904";
}

.icon-globe:before {
  content: "\e90d";
}

.icon-marker:before {
  content: "\f0ca";
}

.icon-external-link:before {
  content: "\e90b";
}

.icon-download:before {
  content: "\e90a";
}

.icon-envelope:before {
  content: "\e90c";
}

.icon-play-arrow:before {
  content: "\e037";
}

.normalized-rte-list {
  margin-bottom: 0.5em;
}
.normalized-rte-list:after {
  content: "";
  display: block;
  width: 1.875em;
  height: 1px;
  background-color: #7C7C7C;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.normalized-rte-list:last-child:after {
  display: none;
}
.normalized-rte-list__item:not(.normalized-rte-list__item--no-comma):not(:last-child):after {
  content: ", ";
}
.normalized-rte-list__item p {
  margin: 0;
}
.normalized-rte-list__item:not(.normalized-rte-list__item--edu-details) p {
  display: inline;
}

.rte .blockquote--no-quote:before {
  content: "" !important;
  display: none !important;
}
.rte blockquote,
.rte .blockquote,
.rte .blockquote--no-quote {
  font-family: 28px;
  font-size: 1.25em;
  font-weight: 500;
  border: 1px solid #E3E3E3;
  border-width: 1px 0;
  line-height: 1.3;
  margin: 1.7777777778em 0;
  padding: 1.5555555556em 5%;
}
.rte blockquote:before,
.rte .blockquote:before,
.rte .blockquote--no-quote:before {
  content: "“";
  display: inline-block;
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-size: 2.5em;
  line-height: 0.2;
  padding-right: 0.2em;
  vertical-align: bottom;
}
.rte blockquote p:first-child,
.rte .blockquote p:first-child,
.rte .blockquote--no-quote p:first-child {
  display: inline;
  padding-left: 0;
  padding-right: 0;
}
.rte blockquote:first-child,
.rte .blockquote:first-child,
.rte .blockquote--no-quote:first-child {
  margin-top: 0;
  border: 0 none;
  padding-top: 0;
  padding-bottom: 0;
}
.rte cite,
.rte .cite {
  font-weight: 300;
}

.print-view-hide {
  display: none;
}

div.print-view-show,
h1.print-view-show,
h2.print-view-show,
h3.print-view-show {
  display: block !important;
}

span.print-view-show {
  display: inline !important;
}

.site-footer,
.site-header,
.site-overlay,
.language-dropdown,
.read-more__button,
.related-insights__more,
canvas,
.accordion__icon {
  display: none;
}

img, figure {
  max-width: 100%;
  height: auto !important;
}

section, .section {
  margin-top: 2em;
}

.titles__subheading-title br {
  display: none;
}

/***********************
* Bio
************************/
.lawyers-listing__meta:not(:last-child):after {
  content: "/";
  margin: 0 0.5em;
}

.profile-heading__position:after {
  content: "/";
  color: #7C7C7C;
  margin-left: 0.65em;
  margin-right: 0.25em;
}
.profile-heading__snapshot {
  width: 200px;
  height: auto !important;
}
.profile-heading__specialty--no-separator::after, .profile-heading__position--no-separator::after {
  display: none;
}
.profile-heading .expandable-services__body {
  height: auto !important;
}

.rte a {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #2354E8;
}
@media screen and (min-width: 48em) {
  .rte a:hover {
    color: #505050;
  }
}