// Link Variants
@mixin links__blue-on-black() {
  @include transition(color, background-color);

  color: $color__blue-light;
  
  @include respond-to('between') {
    &:hover {
      color: $color__white;
    }
  }
}

//in the designs, the blue on white and the blue on light grey both use the same color. 
//only for super dark greys / blacks is the pastel blue used. 

@mixin links__blue-on-light() {
  @include transition(color, background-color);

  color: $color__brand-blue;
  
  @include respond-to('between') {
    &:hover {
      color: $color__gray-charcoal-alt;
    }
  }
}


@mixin links__white-on-black() {
  @include transition(color, background-color);

  color: $color__white;
  
  @include respond-to('between') {
    &:hover {
      color: $color__brand-blue;
    }
  }
}

@mixin links__coral-on-black() {
  @include transition(color, background-color);

  color: $color__brand-coral;
  
  @include respond-to('between') {
    &:hover {
      color: $color__white;
    }
  }
}

@mixin links_blue-hover() {
  @include transition(color);

  @include respond-to('between') {
    &:hover {
      color: $color__brand-blue;
    }
  }
}

@mixin links__external() {
  @include ico-icon-after('external-link') {
    font-size: 1em;
    padding-left: 0.4em;
  }
}