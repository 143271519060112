.rte {
    .blockquote--no-quote {
        &:before{ 
          content: "" !important;
          display: none !important;
        }
      }
    
    blockquote,
    .blockquote,
    .blockquote--no-quote {
        @include font($font-size__copy-blockquote--tablet, $font-size__copy--tablet);
        font-weight: map-get($font-weights, 'medium');
        border: 1px solid $color__border-gray;
        border-width: 1px 0;
        line-height: 1.3;
        margin:  px-to-em($spacing__gutter, $font-size__copy-blockquote) 0;
        padding: px-to-em(28px, $font-size__copy-blockquote)  5%;
    
    
        &:before {
            content: "“";
            display: inline-block;
            @include font($family: $font-family__titles, $weight: 'regular' );
            font-size: 2.5em;
              line-height: 0.2;
              padding-right: 0.2em;
              vertical-align: bottom;
          }
    
        p {
            &:first-child {
                display: inline;
                padding-left: 0;
                padding-right: 0;
            }
        }
    
        &:first-child {
          margin-top: 0;
          border: 0 none;
          padding-top: 0;
          padding-bottom: 0;
        }
    }

    cite,
    .cite {
      font-weight: map-get($map: $font-weights, $key: 'light');
    }
    
    
}