
// Media queries breakpoints
// --------------------------------------------------
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.
// Extra small screen / phone
$screen-xs:                  550px !default;
// Small screen / tablet
$screen-sm:                  768px !default;
// Medium screen / desktop
$screen-md:                  992px !default;
// Large screen / wide desktop
$screen-lg:                  1200px !default;
//additional sizes
$screen-xl:                  1366px !default;


//don't change these without changing javscript
$showcase-desktop:           1000px;
$showcase-desktop--careers:           1000px;

$showcase-tablet-fix: 700px;

$screen-height-landscape--tablet: 769px;

// Height
$screen-height-landscape:           400px;

$breakpoints: (
  'landscape':  px-to-em($screen-xs), // 480px
  'between':  px-to-em($screen-sm), //767px
  'desktop': px-to-em($screen-md), //992px
  'desktop-large':  px-to-em($screen-lg), //1200px
  'desktop-largest':  px-to-em($screen-xl), //1200px
  //modules
  'showcase-desktop': px-to-em($showcase-desktop),
  'showcase-desktop--careers': px-to-em($showcase-desktop),
  //heights
  'height-landscape': px-to-em($screen-height-landscape)
) !default;

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @media screen and (min-width: px-to-em($breakpoint, $font-size-base)) {
      @content;
    }
  }
}

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to-max-height($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media screen and (max-height: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @media screen and (max-height: px-to-em($device-width, $font-size-base)) {
      @content;
    }
  }
}
